import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../../constants/errors";
import { useCreateUser } from "../../hooks/useCreateUser";
import { useLogin } from "../../hooks/useLogin";
import { extractErrorMessage } from "../../utils/error";
import router from "../Routes";
import Auth from "./Auth";
import { Link as MUILink,TextField } from "@mui/material";

const Signup = ()=>{
    const [createUser] = useCreateUser();
    const [username, setUsername] = useState("");
    const [error,setError] = useState("");
    const { login } = useLogin();

    return (<Auth key={"SignupForm"}
                submitLabel={"Signup"}
                error={error}
                extraFields={[
                    <TextField key={"Username"} type={"text"} label={"Username"} value={username} variant={"outlined"}
                               onChange={(event)=>{
                                   setUsername(event.target.value);
                               }}
                               error={ !!error}
                               helperText={error}
                    />
                ]}
                onSubmit={async({ email,password })=>{
        try {
            await createUser({
                variables: {
                    createUserInput: {
                        email,username,password
                    }
                }
            });

            // login after creating user
            await login({ email,password });
            setError("");
        } catch(err) {
            const errorMessage = extractErrorMessage(err);
            if( errorMessage ) {
                setError(errorMessage);
                return;
            }

            setError(UNKNOWN_ERROR_MESSAGE);
        }

    }}>
            <MUILink key={"loginLink"} onClick={()=> router.navigate("/login")} style={{ alignSelf: "center", }}  sx={{ cursor: "pointer" }}>Already have an account? Login</MUILink>
    </Auth>);
}

export default Signup;