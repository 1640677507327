import { useReactiveVar } from "@apollo/client";
import { SyntheticEvent } from "react";
import * as React from 'react';
import { Snackbar as MUISnackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { snackVar } from "../../constants/snack";

const Snackbar = ()=>{
    const snack = useReactiveVar(snackVar);
    const handleClose = (event?: SyntheticEvent | Event,reason?: string)=>{
        if( reason === 'clickaway' ) {
            return;
        }

        snackVar(undefined);
    };

    return (<>
        {
            snack && <MUISnackbar open={true} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={snack.type}
                    sx={{ width: '100%' }}
                >
                    {snack.message}
                </Alert>
            </MUISnackbar>
        }
        </>);
}

export default Snackbar;