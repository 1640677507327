import { useEffect } from "react";
import {authenticatedVar} from "../../constants/authenticated";
import { UNKNOWN_ERROR_SNACK_MESSAGE } from "../../constants/errors";
import excludedRoutes from "../../constants/excluded-routes";
import { snackVar } from "../../constants/snack";
import { useGetMe } from "../../hooks/useGetMe";
import { usePath } from "../../hooks/usePath";
import onLogout from "../../utils/logout";
import {extractErrorMessage} from "../../utils/error";

interface GuardProps {
    children: JSX.Element;
}

const Guard = ({ children }: GuardProps) => {
    const { data: user, error } = useGetMe();
    usePath();

    useEffect(()=>{
        if(user){
            authenticatedVar(true)
        }
    },[user]);


    useEffect(()=>{
        if(error){
            if(error.networkError) { // if  the server is down
                snackVar(UNKNOWN_ERROR_SNACK_MESSAGE);
            } else if(error.graphQLErrors) { // if the user is not authenticated
                const graphqlErrorMessage = extractErrorMessage(error);
                if(graphqlErrorMessage === "Unauthorized") {
                    snackVar({
                        message: "Please login to continue",
                        type: "info",
                    });
                }
            }
            else {
                console.error(error);
            }
        }
    }, [error]);


    return (
        <>
            {excludedRoutes.includes(window.location.pathname)
                ? children
                : user && children}
        </>
    );
};

export default Guard;