import { Link as MUILink } from "@mui/material";
import { useLogin } from "../../hooks/useLogin";
import router from "../Routes";
import Auth from "./Auth";

const Login = ()=>{
    const { login,error } = useLogin();

    return (<Auth key={"LoginForm"} submitLabel={"Login"} onSubmit={(request)=>login(request)}
                  error={error}>
        <MUILink key={"signupLink"}  onClick={()=>router.navigate("/signup")} style={{ alignSelf: "center", }} sx={{ cursor: "pointer" }}>
            Don't have an account? Sign up
        </MUILink>
    </Auth>);
}

export default Login;