import { Button,Stack,TextField } from "@mui/material";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMe } from "../../hooks/useGetMe";

interface AuthProps {
    submitLabel: string;
    onSubmit: (credentials: { email: string;password: string })=>Promise<void>;
    children: React.ReactNode;
    extraFields?: React.ReactNode[];
    error?: string;
}

const Auth = ({ submitLabel,onSubmit,children,error, extraFields }: AuthProps)=>{
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const { data } = useGetMe();
    const navigate = useNavigate();

    useEffect(()=> {
        // if the user is logged in, redirect to the home page
        if( data ) {
            navigate('/');
        }
    },[data,navigate]);

    return (<Stack spacing={3} sx={{
        height: "100vh",maxWidth: 360,margin: "0 auto",justifyContent: "center",
    }}>
        <TextField type={"email"} label={"Email"} value={email} variant={"outlined"}
                   onChange={(event)=>{
                       setEmail(event.target.value);
                   }}
                   error={ !!error}
                   helperText={error}
        />
        {extraFields}
        <TextField key={"Password"} type={"password"} label={"Password"} value={password} variant={"outlined"}
                   onChange={(event)=>{
                       setPassword(event.target.value);
                   }}
                   error={ !!error}
                   helperText={error}
        />
        <Button variant={"contained"} onClick={()=>onSubmit({ email,password })}>{submitLabel}</Button>
        {children}
    </Stack>)
}

export default Auth;