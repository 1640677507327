import { ApolloClient,from,HttpLink,InMemoryCache,split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { extractErrorMessage } from "../utils/error";
import { getToken } from "../utils/token";
import { snackVar } from "./snack";
import { API_URL, WS_URL } from "./urls";
import excludedRoutes from "./excluded-routes";
import { onLogout } from "../utils/logout";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

const logoutLink = onError((error) => {
    if (
        error.graphQLErrors?.length &&
        (error.graphQLErrors[0].extensions?.originalError as any)?.statusCode ===
        401
    ) {
        if (!excludedRoutes.includes(window.location.pathname)) {
            onLogout();
        }
    }
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: getToken(),
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        const errorSnackMessage = extractErrorMessage(graphQLErrors);
        if (errorSnackMessage) {
            snackVar({
                message: errorSnackMessage,
                type: "error",
            });
        }
    }

    if (networkError) {
        // handle network error
        console.log(`[Network error]: ${networkError}`);
        // check if network is socket error and re-connect
        if (networkError.message.includes("Socket")) {
            snackVar({
                message: networkError.message,
                type: "warning",
            });
        }
    }
});

const httpLink = new HttpLink({ uri: `${API_URL}/graphql` });

const wsLink = new GraphQLWsLink(
    createClient({
        url: `${WS_URL}/graphql`,
        connectionParams: {
            Authorization: getToken(),
        }
    })
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    httpLink
);

const appLink = from([
    errorLink,
    logoutLink.concat(authLink).concat(splitLink)
])


const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    chats: {
                        keyArgs: false,
                        merge,
                    },
                    messages: {
                        keyArgs: ["chatId"],
                        merge,
                    },
                },
            },
        },
    }),
    link: appLink,
    defaultOptions: (process.env.NODE_ENV === "development") ? {
        watchQuery: {
            errorPolicy: 'all'
        },
        query: {
            errorPolicy: 'ignore'
        },
        mutate: {
            errorPolicy: 'none'
        }
    } : {},


});

function merge(existing: any, incoming: any, { args }: any) {
    const merged = existing ? existing.slice(0) : [];
    for (let i = 0; i < incoming.length; ++i) {
        merged[args.skip + i] = incoming[i];
    }
    return merged;
}



export default client;