import Snackbar from "../snackbar/Snackbar";

const Home = () => {
    return (
    <>
        <div>
            <h1>Home</h1>
        </div>
        <Snackbar/>
    </>
    );
};

export default Home;