import Typography from "@mui/material/Typography";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import router from "../Routes";

const Branding = () => {
    return (
        <>
            <RemoveRedEyeOutlinedIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => router.navigate('/')}
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    cursor: 'pointer',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                DEXDIP
            </Typography>
        </>

    );
}

export default Branding;