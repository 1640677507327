const extractErrorMessage = (err: any) => {

    // if the error is not a graphql error, return
    const errorMessage = err.graphQLErrors? err.graphQLErrors[0]?.extensions?.originalError?.message : err.message;
    if (!errorMessage) {
        return;
    }
    if (Array.isArray(errorMessage)) {
        return formatErrorMessage(errorMessage[0]);
    } else {
        return formatErrorMessage(errorMessage);
    }
};

const formatErrorMessage = (errorMessage: string) => {
    return errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
};

export { extractErrorMessage };