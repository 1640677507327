import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, ListItemButton } from "@mui/material";
import router from "../../Routes";
import { Chat } from "../../../gql/graphql";

interface ChatListProps {
    chat: Chat;
    selected: boolean;
}

const ChatListItem = ({ chat, selected }: ChatListProps) => {
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemButton
                    onClick={() => router.navigate(`/chats/${chat._id}`)}
                    selected={selected}
                >
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={chat.name}
                        style={{ fontSize: "1rem"}}
                        secondary={
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "0.5rem",
                                }}
                            >
                                <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {chat.latestMessage?.user.username || ""}
                                </Typography>
                                <div style={
                                    {
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 1,
                                        lineClamp: 1,
                                        WebkitBoxOrient: "vertical",
                                    }
                                }>
                                    {" " + (chat.latestMessage?.content || "")}
                                </div>
                            </Box>
                        }
                    />
                </ListItemButton>
            </ListItem>
            <Divider variant="inset" />
        </>
    );
};

export default ChatListItem;