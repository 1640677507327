import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import { Page } from "../../interfaces/page.interface";
import router from "../Routes";

interface NavigationProps {
    pages: Page[];
}

const Navigation = ({pages}: NavigationProps) => {
    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page: Page) => (
                <Button
                    key={page.title}
                    onClick={() => router.navigate(page.path)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    {page.title}
                </Button>
            ))}
        </Box>
    );
}

export default Navigation;